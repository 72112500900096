import { ComponentType, useEffect } from "react"

// Made with love by Clément: https://lionneclement.com/
// Full tutorial: https://framermart.com/tutorial/how-to-track-utm-parameters-in-framer-forms

export function withAllParams(Component): ComponentType {
    return (props: any) => {
        useEffect(() => {
            if (typeof window !== "undefined") {
                const params = new URLSearchParams(window.location.search)
                const form = document.querySelector(`form.${props.className}`)

                if (form) {
                    params.forEach((value, key) => {
                        const input = document.createElement("input")
                        input.type = "hidden"
                        input.name = key
                        input.value = value
                        form.appendChild(input)
                    })
                }
            }
        }, [props.className])

        return <Component {...props} />
    }
}

export function withUTMParams(Component): ComponentType {
    return (props: any) => {
        useEffect(() => {
            if (typeof window !== "undefined") {
                const params = new URLSearchParams(window.location.search)
                const form = document.querySelector(`form.${props.className}`)

                if (form) {
                    params.forEach((value, key) => {
                        if (key.startsWith("utm")) {
                            const input = document.createElement("input")
                            input.type = "hidden"
                            input.name = key
                            input.value = value
                            form.appendChild(input)
                        }
                    })
                }
            }
        }, [props.className])

        return <Component {...props} />
    }
}
